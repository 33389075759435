@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import url("https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap");

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.p-datatable .p-datatable-tbody > tr:nth-child(odd) {
  background: #52617b;
  color: white;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: #374860;
  color: white;
}

.p-datatable .p-column-header-content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: center;
    /* border: 1px dotted gray; */
    padding: 1rem 1rem;
    border-right: 1px dashed gray;
    
}

body {
  padding: 0;
  margin: 0;
  font-family: lato, sans-serif;
  font-size: 16px;
  background-color: #f9f9f9;
}
.logoKccb {
  width: 100px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.page-title-area {
  padding-top: 35px;
  padding-bottom: 32px;
  background-color: #f5f5f5;
}

.page-title-content {
  position: relative;
}

.page-title-content h2 {
  margin-bottom: 0;
  font-size: 30px;
}

.page-title-content ul {
  text-align: right;
  position: absolute;
  right: 0;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.page-title-content ul li {
  display: inline-block;
  margin-left: 20px;
  font-weight: 500;
  color: #136;
  position: relative;
  font-size: 16px;
}

.page-title-content ul li::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 10px;
  height: 8px;
  width: 8px;
  background: #136;
  border-radius: 50px;
}

.page-title-content ul li:first-child {
  margin-left: 0;
}

.page-title-content ul li:first-child::before {
  display: none;
}

.page-title-content ul li a {
  display: block;
}

.logoutBtn {
  background-color: rgb(218, 60, 60);
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
}

.swal-title {
  color: rgb(218, 60, 60);
}

.swal-text {
  color: white;
}

.swal-content {
  padding: 0 20px;
  margin-top: 9px;
  font-size: 15px;
  overflow-wrap: break-word;
  color: white;
}

.link_hash {
  color: white;
}

.modal_class {
  background-color: #0c2347;
}

.swal-text {
  color: #fff;
  text-align: center !important;
}

.swal-footer {
  text-align: center;
}

.swal-modal {
  background-color: #0c2347;
}

.modal_class_success .swal-icon::before {
  background-color: transparent;
}

.modal_class_success .swal-icon::after {
  background-color: transparent;
}

.swal-icon--success__hide-corners {
  background-color: transparent;
}

.success {
  color: #ff343f;
  margin-top: 1rem;
  padding-bottom: 1.5rem;
}

a:hover,
a:focus {
  color: #136;
  text-decoration: none;
}

button {
  outline: 0 !important;
}

p {
  margin-bottom: 15px;
  line-height: 1.8;
  /* color: #656565; */
  font-weight: 500;
  font-family: lato, sans-serif;
  font-size: 16px;
  /* line-break: anywhere; */
}

p:last-child {
  margin-bottom: 0;
}

i {
  line-height: normal;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #292929;
  font-family: poppins, sans-serif;
  font-weight: 600;
}

.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: #136;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
  font-weight: 500;
  padding: 15px 35px;
}

.default-btn span {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #fff;
  color: #fff;
  -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 5px;
  opacity: 1;
}

.default-btn i {
  margin-right: 5px;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.default-btn:hover {
  color: #292929 !important;
}

.default-btn:hover span {
  width: 225%;
  height: 562.5px;
}

.default-btn:hover i {
  color: #292929;
}

.login-form h2 {
  margin-bottom: 30px;
  font-size: 25px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  font-weight: 600;
  position: relative;
}

.login-form h2::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #136;
  height: 100%;
  width: 100px;
}

.login-form form .form-group {
  margin-bottom: 25px;
}

.login-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  color: #656565;
  font-weight: 400;
  font-size: 16px;
}

.login-form form .form-group .form-control {
  height: 60px;
  padding: 0 0 0 15px;
  line-height: initial;
  color: #292929;
  background-color: transparent !important;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 500;
}

.login-form form .form-group .form-control:focus {
  border: 1px solid #136;
}

.login-form form .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-form form .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-form form .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-form form .form-group .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-form form .lost-your-password {
  text-align: right;
}

.login-form form .lost-your-password a {
  display: inline-block;
  position: relative;
  font-weight: 500;
  font-size: 15px;
  color: #656565;
}

.login-form form .lost-your-password a::before {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #eee;
}

.login-form form .lost-your-password a::after {
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  bottom: 0;
  content: "";
  background-color: #ed1d24;
}

.login-form form .lost-your-password a:hover::before {
  width: 0;
}

.login-form form .lost-your-password a:hover::after {
  width: 100%;
}

.login-form form .form-check {
  font-weight: 500;
  font-size: 15px;
  color: #656565;
}

.login-form form .form-check-input {
  position: absolute;
  margin-top: 4px;
  margin-left: -18px;
}

.login-form form button {
  border: none;
  margin-top: 25px;
  padding: 15px 30px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  background-color: #136;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-form form button:hover {
  background-color: #ed1d24;
}

.login-form .important-text {
  margin-top: 15px;
  text-align: center;
}

.login-form .important-text p {
  font-size: 15px;
  color: #656565;
}

.login-form .important-text p a {
  color: #656565;
  font-weight: 600;
}

.login-form .important-text p a:hover {
  color: #136;
}

.login-form.mb-30 {
  margin-bottom: 30px;
}

.register-form h2 {
  margin-bottom: 30px;
  font-size: 25px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  font-weight: 600;
  position: relative;
}

.register-form h2::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #136;
  height: 100%;
  width: 100px;
}

.register-form form .form-group {
  margin-bottom: 25px;
}

.register-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  color: #656565;
  font-weight: 400;
  font-size: 16px;
}

.register-form form .form-group .form-control {
  height: 60px;
  padding: 0 0 0 15px;
  line-height: initial;
  color: #292929;
  background-color: transparent !important;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 500;
}

.register-form form .form-group .form-control:focus {
  border: 1px solid #136;
}

.register-form form .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-form form .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-form form .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-form form .form-group .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-form form .form-check {
  font-weight: 500;
  font-size: 15px;
  color: #656565;
}

.register-form form .form-check-input {
  position: absolute;
  margin-top: 4px;
  margin-left: -18px;
}

.register-form form button {
  border: none;
  margin-top: 25px;
  padding: 15px 30px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  background-color: #136;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-form form button:hover {
  background-color: #ed1d24;
}

.register-form .important-text {
  margin-top: 15px;
  text-align: center;
}

.register-form .important-text p {
  font-size: 15px;
  color: #656565;
}

.register-form .important-text p a {
  color: #656565;
  font-weight: 600;
}

.register-form .important-text p a:hover {
  color: #136;
}
.login-form,
.register-form,
.add-product-form {
  padding: 40px 45px;
  border-radius: 5px;
  background-color: #fff;
  max-width: 650px;
  margin: auto;
}
