.login-form h3 {
  margin-bottom: 30px;
  font-size: 25px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  font-weight: 600;
  position: relative;
}

.bannerDivv {
  height: 90vh;
}
.todaysBookingDiv{
  background-color: #1a1c33;
  height: 100vh;
  padding: 25px;
}
.banner {
  background-image: url("./billiard.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 240px 0;
  z-index: 1;
  position: relative;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(13, 12, 12, 0.164);
  z-index: -1;

}

.banner-btn button {
  background: linear-gradient(90deg, #330000 0%, #F9975B 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px 0;
  color: white;
  font-weight: 600;
  font-size: 20px;
  border: 0px;
  min-width: 200px;
  transition: 1s all ease-in-out;
}

.banner-btn button:hover {
  background: linear-gradient(90deg, #F9975B 0%, #330000 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.banner-btn button:nth-child(1) {
  margin-right: 20px;
}

.banner-wrapper {
  text-align: center;

}

.banner-text {
  color: white;
  font-family: 'Poppins', sans-serif;
}

.banner-text h6 {
  margin: 15px 0 50px 0;
  font-size: 24px;
}

.banner-text h1 {
  font-size: 96px;
  background-image: url("https://i.ibb.co/gZDYdZc/nmn-1.png");
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  filter: drop-shadow(-4px 6px 4px #000000);
}

.banner-text h4 {
  font-size: 42px;
}


.upperArrowIcon {
  border-radius: 35px;
  position: fixed;
  right: 2%;
  bottom: 2%;
  cursor: pointer;
  background-color: #15407f;
  color: #ffffffe8;
}
.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upperArrowIcon:hover {
  border: 4px solid #e1d7ba;
}
.modal_class {
  background-color: #0c2347;
}

.swal-modal {
  background-color: #0c2347;
}

.swal-icon--success__hide-corners {
  background-color: transparent;
}

.success {
  color: #ff343f;
  margin-top: 1rem;
  padding-bottom: 1.5rem;
}

.extraCare {
  margin-top: 15px;
}

.swal-text {
  text-align: center;
  color: #ff343f;
  font-weight: normal;
}

/* swal */
.modal_class_success {
  background-color: #2d313a;
}

.swal-text {
  color: #fff;
  text-align: center !important;
}

.swal-icon--success__hide-corners {
  background-color: transparent;
}

.address {
  font-weight: normal;
  width: 80%;
  color: #8fb9d4;
}
.swal-content {
  padding: 0 20px;
  margin-top: 9px;
  font-size: 13px;
  overflow-wrap: break-word !important;
  color: white;
}

.swal-title {
  color: #ff343f;
}

.swal-text {
  color: #fff;
}

.swal-icon--success:after,
.swal-icon--success:before {
  background-color: inherit !important;
}





@media (max-width: 992px) {

  .banner-text h6 {
      margin: 15px 0 50px 0;
      font-size: 18px;
  }

  .banner-text h1 {
      font-size: 76px;
      letter-spacing: normal;
  }

  .banner-text h4 {
      font-size: 32px;
  }
}

@media (max-width: 767px) {

  .banner-text h6 {
      margin: 15px 0 30px 0;
      font-size: 16px;
  }

  .banner-text h1 {
      font-size: 56px;
      letter-spacing: normal;
  }

  .banner-text h4 {
      font-size: 28px;
  }

  .banner-btn button {
      padding: 7px 0;
      font-size: 16px;
      min-width: 150px;
  }


}

@media (max-width: 576px) {
  .banner {
      padding: 180px 0;
  }

  .banner-text h6 {
      font-size: 14px;
  }

  .banner-text h1 {
      font-size: 46px;
      letter-spacing: normal;
  }

  .banner-text h4 {
      font-size: 24px;
  }
}

@media (max-width: 480px) {
  .banner-text h6 {
      font-size: 14px;
      margin: 10px 0 30px 0;
  }

  .banner-text h1 {
      font-size: 38px;
      letter-spacing: normal;

  }

  .banner-text h4 {
      font-size: 22px;
  }

  .banner-btn button:nth-child(1) {
      margin-right: 0px;
      display: block;
      margin: 0 auto;
      margin-bottom: 15px;
  }
}

@media (max-width: 361px) {
  .banner-text h1 {
      font-size: 35px;

  }
}