body {
  background-color: black;
  /* padding: 60px; */
}

.header {
  width: 100%;
  height: 190px;
  background-image: url("./navbar.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.date-time {
  color: white;
  font-family: "RocknRoll One", sans-serif;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-right: 130px;
  margin-bottom: 30px;
}

.date-time h1 {
  size: 30px;
  color: white;
  text-align: right;
}

.date-time h2 {
  size: 20px;
  color: white;
  text-align: right;
}

.bill {
 display: flex;
 align-items: center;
 color: white;
 gap: 15px;
 justify-content: center;
}

.name {
  color: white;
  font-family: "RocknRoll One", sans-serif;
  size: 20px;
  text-align: left;
  line-height: 0;
}

.time {
  color: white;
  font-family: "RocknRoll One", sans-serif;
  size: 20px;
  
}

.sl {
  color: white;
  font-family: "RocknRoll One", sans-serif;
  size: 24px;
  
}

.guest {
  color: white;
  font-family: "RocknRoll One", sans-serif;
  size: 16px;
  display: block;
  text-align: left;
}
