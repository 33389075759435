.imgAdmin {
    width: 60px;
    border-radius: 50%;
}

.adminCard {
    background-color: #272D47;
    border-radius: 10px;
    overflow: auto;
}

.loginform{
    margin-top: 90px;
    margin-bottom: 90px;
}

.adminBtn {
    background-color: #6958BE;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px;
    margin-bottom: 2rem;
    display: flex;
    /* margin-left: 0.6rem; */
    margin-top: 1rem;
}

.adminDataTable {
    border: none;
    overflow: scroll;
}

.tableRow {
    padding: 2rem;
}

.AccessBtn {
    border: none;
    background-color: #42C48A;
    padding: 5px 5px;
    border-radius: 5px;
}

.deleteBtn {
    border-radius: 5px;
    background-color: #EF5858;
    padding: 5px 5px;
    border: none;
    margin-left: 5px;
}

.editBtn {
    border-radius: 5px;
    background-color: #42C48A;
    padding: 5px 5px;
    border: none;
    margin-left: 3px;
}

.exclamatoryIcon {
    color: black;
    font-size: 3rem;
}

.delete {
    background-color: #272D47;
}

.deleteDiv p {
    color: white;
}

.Icon {
    text-align: center;
    font-size: 4.5rem;
    color: #EF5858;
}

.copyrightAdmin {
    background-color: #272D47;
    padding: 10px;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.adminBtnDiv {
    /* margin-right: 3rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.activeLegends {
    margin-bottom: -1.2rem;
}

/* .tableNormal{
    overflow: scroll;
} */

.addAdminImg {
    width: 100px;
    border-radius: 50%;
}

.modelAddAdmin {
    /* background: linear-gradient(to right, #02aab0 0%, #00cdac 51%, #02aab0 100%); */
    background-color: #303651 !important;
    /* z-index: 100; */
    padding-top: 40px;
    padding-right: 50px;
}


.model {
    padding-top: 40px;
}



.dailogueAdmin {
    background-color: #272d47;
}

.adminBtnAdd {
    background-color: #02aab0;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 140px;
}

.adminBtnAdd11 {
    background-color: rgb(241, 89, 89);
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 140px;
}

.addAdminImgBtn {
    background-color: #6958be;
    color: white;
    border: none;
    border-radius: 7px;
    margin-bottom: 0.5rem;
}

.addAdminDiv p {
    color: rgb(177, 185, 195);
    margin-bottom: -0.2rem;
    margin-top: 1rem;
    background-color: #303651 !important;
}

.handleModalBody {
    background-color: #303651 !important;
}

.linkTitle {
    color: rgb(177, 185, 195);
}

.addAdminDiv input,
textarea {
    background-color: transparent;
    padding: 6px;
    border: 1px solid rgb(177, 185, 195);
    color: rgb(177, 185, 195);
    border-radius: 5px;
    width: 100%;
}


@media only screen and (max-width: 994px) {
    .tableNormal {
        overflow: scroll;
    }
}

@media only screen and (max-width: 820px) {
    .editBtn {
        border-radius: 5px;
        background-color: #42C48A;
        padding: 5px 5px;
        border: none;
        margin-left: -6px;
    }

    .tableNormal {
        overflow: scroll;
    }
}

@media only screen and (max-width: 500px) {
    .tableNormal {
        overflow: scroll;
    }

    .adminBtnDiv {
        margin-right: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title-table-hide {
        display: none;
    }

    .AccessBtn {
        border: none;
        background-color: #42C48A;
        padding: 5px 5px;
        border-radius: 5px;
        margin-left: 0rem;
    }

    .editBtn {
        border-radius: 5px;
        background-color: #42C48A;
        padding: 5px 5px;
        border: none;
        margin-left: -14px;
    }

    .deleteBtn {
        border-radius: 5px;
        background-color: #EF5858;
        padding: 5px 5px;
        border: none;
        border-top: 2px;
    }

    .actionDiv {
        height: 4.1rem;
        margin-left: 1rem;
    }

    .action {
        width: 9rem;
    }

    .adminHidden {
        display: none;
    }
}

@media only screen and (max-width: 350px) {
    .adminBtnDiv {
        margin-right: 1rem;
    }

}